import { IdentityConfig } from '../config/auth-config';
import { UserManager } from 'oidc-client';

const userManager = new UserManager({
  ...IdentityConfig,
  // userStore: new WebStorageStateStore({ store: window.sessionStorage }),
});

const loginUser = async () => {
  const hash = window.location.hash.substring(1);
  if (hash && new URLSearchParams(hash).get('state')) {
    try {
      await userManager.signinRedirectCallback();
      window.location = '/';
      return;
    } catch (e) {
      console.error(e);
    }
  }
  
  userManager.signinRedirect();
};

export const getLoggedInUser = async () => {
  const hash = window.location.hash.substring(1);
  if (hash) {
    loginUser();
    return;
  }

  const user = await userManager.getUser();
  if (user && !user.expired)
    return user;

  loginUser();
};

export const logout = async () => {
  await userManager.signoutRedirect();
}