import { Route, Switch } from "react-router-dom";
import DashboardView from "./views/DashboardView";
import CustomersView from "./views/CustomersView";

export default function Routing() {
  return (
    <Switch>
      <Route path="/customers" component={CustomersView} />
      <Route path="/" component={DashboardView} />
    </Switch>
  );
}