import { useHistory } from "react-router-dom";
import * as api from "../../services/basiccrm-api";
import CustomerDetails from "./CustomerDetails";

export default function AddCustomer() {
  const history = useHistory();

  const saveCustomer = async customer => {
    try {
      const createdCustomer = await api.addCustomer(customer);
      history.push(`/customers/${createdCustomer.id}`);
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <div>
      <div className="is-title-bar">
        <h1 className="title">Add Customer</h1>
      </div>

      <div className="is-detail-container">
        <CustomerDetails onSave={saveCustomer} onCancel={() => history.push('/customers')} />
      </div>
    </div>
  );
}