import { createContext } from "react";

const context = createContext();

export const AppProvider = ({ children }) => {
  const providerValues = {};

  return <context.Provider value={providerValues}>
    {children}
  </context.Provider>
}

export default context;