import "bulma/css/bulma.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./App.css";
import { useState, useEffect } from "react";
import { getLoggedInUser, logout } from "./services/user-manager";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import Routing from "./components/Routing";
import publicRoutes from "./public-routes.json";
import PublicRouting from "./components/PublicRouting";
import { AppProvider } from "./contexts/AppContext";

const checkIsPublicRoute = () => publicRoutes.includes(window.location.pathname);

function App() {
  const [isPublicRoute] = useState(checkIsPublicRoute());
  const [user, setUser] = useState(null);
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);

  const toggleMobileNavigation = () => {
    setShowMobileNavigation(!showMobileNavigation);
  };

  useEffect(() => {
    const checkLoggedIn = async () => {
      const user = await getLoggedInUser();
      setUser(user);
    };

    if (!isPublicRoute)
      checkLoggedIn();
  }, [isPublicRoute]);

  if (isPublicRoute)
    return <Router>
      <PublicRouting />
    </Router>

  if (!user)
    return <span>Loading...</span>;

  return (
    <AppProvider>
      <Router>
        <div className="columns is-fullheight">
          <div className={`column is-sidebar-menu ${!showMobileNavigation ? "is-hidden-mobile" : ""}`}>
            <aside className="menu">
              <div className="block sidebar-logo">
                <h1 className="subtitle is-4">
                  BasicCRM
                </h1>
              </div>
              <p className="menu-label">
                General
              </p>
              <ul className="menu-list">
                <li><Link to="/">Dashboard</Link></li>
                <li><Link to="/customers">Customers</Link></li>
              </ul>
              <p className="menu-label">
                Account
              </p>
              <ul className="menu-list">
                <li><Link to="" onClick={logout}>Log Out</Link></li>
              </ul>
            </aside>
          </div>
          <div className="column is-main-content">
            <div className="is-mobile-nav-toggle is-hidden-tablet" onClick={toggleMobileNavigation}>
              <i className="fas fa-bars"></i>
            </div>
            <Routing />
          </div>
        </div>
      </Router>
    </AppProvider>
  );
}

export default App;
