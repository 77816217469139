import { useEffect, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import * as api from "../../../services/basiccrm-api";
import HttpException from "../../../exceptions/HttpException";
import { useHistory } from "react-router-dom";

export default function SignupView() {
  const [customer] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const updateCardElement = () => {
      const cardElement = elements?.getElement(CardElement);

      if (cardElement)
        cardElement.update({ disabled: loading });
    };

    updateCardElement();
  }, [loading, elements]);

  if (!stripe || !elements)
    return '';

  const handleError = error => {
    setLoading(false);

    if ((error instanceof HttpException && error.status === 400) || error.message)
      setErrorMessage(error.message);

    console.error(error);
  }

  const getCompanyDetails = form => {
    const companyDetails = {
      address: {}
    };

    [...form.elements].forEach(el => {
      if (el.name) {
        const value = el.selectedIndex ? el.options[el.selectedIndex].value : el.value;

        if (el.getAttribute('parent'))
          companyDetails[el.getAttribute('parent')][el.name] = value;
        else
          companyDetails[el.name] = value;
      }
    });

    return companyDetails;
  };

  const validateCompanyDetails = companyDetails => {
    // if (!companyDetails.password)
    //   throw new Error("Please provide a password")

    // if (companyDetails.password !== companyDetails.confirmPassword)
    //   throw new Error("Your passwords do not match");

    // const pwRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,25}$/;
    // if (!pwRegex.test(companyDetails.password))
    //   throw new Error("Please ensure that your password is at least 8 characters long, containing both a number and a special character");
  };

  const signup = async e => {
    e.preventDefault();

    const companyDetails = getCompanyDetails(e.target);

    try {
      validateCompanyDetails(companyDetails);
    } catch (error) {
      setErrorMessage(error.message);
      return;
    }

    setLoading(true);

    let signupData;
    try {
      signupData = await api.signup(companyDetails);
    } catch (err) {
      handleError(err);
      return;
    }

    // trigger Stripe card payment
    const cardElement = elements.getElement(CardElement);
    const { error, paymentIntent } = await stripe.confirmCardPayment(signupData.key, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: `${companyDetails.title} ${companyDetails.firstName} ${companyDetails.lastName}`,
          address: {
            line1: companyDetails.address.address1,
            line2: companyDetails.address.address2,
            city: companyDetails.address.city,
            postal_code: companyDetails.address.postcode,
            country: companyDetails.address.country
          }
        }
      }
    });

    if (error) {
      handleError(error);
      return;
    }

    if (paymentIntent && paymentIntent.status === 'succeeded') {
      history.push(`/confirmation?id=${signupData.id}&key=${signupData.key}`);
    } else {
      setErrorMessage("Payment intent status has not succeeded... 🤔");
    }
  };

  return (
    <div className="container p-5">
      <h1 className="title">Sign Up</h1>

      <div className="columns">
        <div className="column">

          <div className="box">
            <h2 className="title is-4">Basic Subscription</h2>
            <h2 className="subtitle">&pound;39.99 / monthly</h2>
          </div>

        </div>
        <div className="column">

        </div>
      </div>

      <form className="app-form block" onSubmit={signup}>
        <fieldset disabled={loading}>
          <div className="field">
            <label className="label" htmlFor="companyName">Company Name</label>
            <div className="control">
              <input className="input" type="text" name="companyName" required defaultValue={customer?.companyName} />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="email">Email</label>
            <div className="control">
              <input className="input" type="email" name="email" required defaultValue={customer?.email} />
            </div>
          </div>

          {/* <div className="field">
            <label className="label" htmlFor="password">Password</label>
            <div className="control">
              <input className="input" type="password" name="password" required />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="confirmPassword">Confirm Password</label>
            <div className="control">
              <input className="input" type="password" name="confirmPassword" required />
            </div>
          </div> */}

          <div className="field">
            <label className="label" htmlFor="title">Title</label>
            <div className="control">
              <div className="select">
                <select name="title" defaultValue={customer?.title}>
                  <option value="">N/A</option>
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                  <option value="Miss">Miss</option>
                  <option value="Mrs">Mrs</option>
                </select>
              </div>
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="firstName">First Name</label>
            <div className="control">
              <input className="input" type="text" name="firstName" required defaultValue={customer?.firstName} />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="lastName">Last Name</label>
            <div className="control">
              <input className="input" type="text" name="lastName" required defaultValue={customer?.lastName} />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="address1">Address Line 1</label>
            <div className="control">
              <input className="input" type="text" name="address1" parent="address" required defaultValue={customer?.address?.address1} />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="address2">Address Line 2</label>
            <div className="control">
              <input className="input" type="text" name="address2" parent="address" defaultValue={customer?.address?.address2} />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="city">City</label>
            <div className="control">
              <input className="input" type="text" name="city" parent="address" required defaultValue={customer?.address?.city} />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="postcode">Postcode</label>
            <div className="control">
              <input className="input" type="text" name="postcode" parent="address" required defaultValue={customer?.address?.postcode} />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="country">Country</label>
            <div className="control">
              <input className="input" type="text" name="country" parent="address" required defaultValue={customer?.address?.country} />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="telephone">Telephone</label>
            <div className="control">
              <input className="input" type="text" name="telephone" defaultValue={customer?.telephone} />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="card_details">Card Details</label>
            <div className="box">
              <CardElement />
            </div>
          </div>

          <div className="block">
            <div className="field is-grouped">
              <p className="control">
                <button className="button is-success" type="submit">
                  <span className="icon is-small">
                    <i className={`fas ${loading ? "fa-hourglass" : "fa-check"}`}></i>
                  </span>
                  <span>{loading ? "Please wait..." : "Register"}</span>
                </button>
              </p>
            </div>
          </div>
        </fieldset>
      </form>

      <a href="/">Already registered?</a>

      <div className={`modal ${errorMessage ? 'is-active' : ''}`}>  <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Error</p>
            <button className="delete" aria-label="close" onClick={() => setErrorMessage(null)}></button>
          </header>
          <section className="modal-card-body">
            {errorMessage}
          </section>
          <footer className="modal-card-foot">
            {/* <button className="button" onClick={() => setErrorMessage(null)}>Close</button> */}
          </footer>
        </div>
      </div>

    </div>
  )
}