import HttpException from "../exceptions/HttpException";
import { getLoggedInUser } from "./user-manager";

const http = async (url, method = 'GET', body = null, provideToken = true) => {
  const init = {
    method,
    headers: { 'Content-Type': 'application/json' }
  };

  if (provideToken) {
    const user = await getLoggedInUser();
    init.headers['Authorization'] = `Bearer ${user?.access_token}`;
  }

  if (body)
    init.body = JSON.stringify(body);

  const response = await fetch(url, init);

  await checkResponseIsOk(response);

  return response;
}

const getJson = async (url, method = 'GET', body = null, provideToken = true) => {
  const response = await http(url, method, body, provideToken);

  return await response.json();
}

const checkResponseIsOk = async response => {
  if (!response.ok) {
    let message = null;

    try {
      const data = await response.json();
      message = data.detail || message;
    } catch (e) { }

    throw new HttpException(response.status, message);
  }
}

export {
  http,
  getJson
};