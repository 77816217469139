import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import * as api from "../../services/basiccrm-api";

export default function ListCustomers() {
  const history = useHistory();
  const [customers, setCustomers] = useState(null);

  useEffect(() => {
    const getCustomers = async () => {
      const data = await api.getCustomers();
      setCustomers(data);
    }

    getCustomers();
  }, []);


  return <div>
    <div className="is-title-bar">
      <h1 className="title">Customers</h1>
    </div>

    <div className="block">
      <Link className="button" to="/customers/add">
        <span className="icon is-small">
          <i className="fas fa-plus"></i>
        </span>
        <span>
          Add Customer
        </span>
      </Link>
    </div>

    {!customers &&
      <div>Loading...</div>}

    {customers && !customers.length &&
      <div>No customers found</div>}

    {customers && !!customers.length &&
      <table className="table is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Title</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Address</th>
            <th>Contact</th>
          </tr>
        </thead>
        <tbody>
          {customers.map(customer =>
            <tr key={customer.id} className="clickable" onClick={() => history.push(`/customers/${customer.id}`)}>
              <td>{customer.title}</td>
              <td>{customer.firstName}</td>
              <td>{customer.lastName}</td>
              <td>
                {customer.address.address1}
                {(customer.address.city || customer.address.city) &&
                  <span><br />{customer.address.city} {customer.address.postcode}</span>}
                {customer.address.country && <span><br />{customer.address.country}</span>}
              </td>
              <td>{customer.telephone}<br />{customer.email}</td>
            </tr>)}
        </tbody>
      </table>
    }
  </div>;
}