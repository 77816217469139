import config from '../config.json';

const getConfigValue = name => (window._env && window._env[name]) || config[name];

export const _config = Object.keys(config).reduce((obj, key) => {
  obj[key] = getConfigValue(key);
  return obj;
}, {});

export const BASICCRM_API_URL = getConfigValue('BASICCRM_API_URL');
export const BASICCRM_AUDIENCE = getConfigValue('BASICCRM_AUDIENCE');
export const BASICCRM_PWA_CLIENT_ID = getConfigValue('BASICCRM_PWA_CLIENT_ID');
export const BASICCRM_OIDC_DOMAIN = getConfigValue('BASICCRM_OIDC_DOMAIN');
export const STRIPE_PUBLISHABLE_KEY = getConfigValue('STRIPE_PUBLISHABLE_KEY');
