import * as config from "../config/app-config";

export const IdentityConfig = {
  authority: config.BASICCRM_OIDC_DOMAIN, //(string): The URL of the OIDC provider.
  client_id: config.BASICCRM_PWA_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: window.location.origin, //The URI of your client application to receive a response from the OIDC provider.
  login: config.BASICCRM_OIDC_DOMAIN + "/login",
  automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: window.location.origin + '/silent-renew.html', //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: window.location.origin, // (string): The OIDC post-logout redirect URI.
  audience: config.BASICCRM_AUDIENCE, //is there a way to specific the audience when making the jwt
  response_type: "id_token token", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  // grantType: "password",
  scope: "openid", //(string, default: 'openid'): The scope being requested from the OIDC provider.
  webAuthResponseType: "id_token token"
};