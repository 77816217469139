export default function CustomerDetails({ customer, onSave, onCancel, onDelete }) {
  const save = e => {
    e.preventDefault();

    const customerDetails = {
      address: {}
    };

    [...e.target.elements].forEach(el => {
      if (el.name) {
        const value = el.selectedIndex ? el.options[el.selectedIndex].value : el.value;

        if (el.getAttribute('parent'))
          customerDetails[el.getAttribute('parent')][el.name] = value;
        else
          customerDetails[el.name] = value;
      }
    });

    onSave(customerDetails);
  };

  return (
    <div className="">
      <form className="app-form block" onSubmit={save}>

        <div className="field">
          <label className="label" htmlFor="title">Title</label>
          <div className="control">
            <div className="select">
              <select name="title" defaultValue={customer?.title}>
                <option value="">N/A</option>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Miss">Miss</option>
                <option value="Mrs">Mrs</option>
              </select>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor="firstName">First Name</label>
          <div className="control">
            <input className="input" type="text" name="firstName" defaultValue={customer?.firstName} />
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor="lastName">Last Name</label>
          <div className="control">
            <input className="input" type="text" name="lastName" defaultValue={customer?.lastName} />
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor="telephone">Telephone</label>
          <div className="control">
            <input className="input" type="text" name="telephone" defaultValue={customer?.telephone} />
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor="email">Email</label>
          <div className="control">
            <input className="input" type="text" name="email" defaultValue={customer?.email} />
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor="address1">Address Line 1</label>
          <div className="control">
            <input className="input" type="text" name="address1" parent="address" defaultValue={customer?.address?.address1} />
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor="address2">Address Line 2</label>
          <div className="control">
            <input className="input" type="text" name="address2" parent="address" defaultValue={customer?.address?.address2} />
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor="city">City</label>
          <div className="control">
            <input className="input" type="text" name="city" parent="address" defaultValue={customer?.address?.city} />
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor="postcode">Postcode</label>
          <div className="control">
            <input className="input" type="text" name="postcode" parent="address" defaultValue={customer?.address?.postcode} />
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor="country">Country</label>
          <div className="control">
            <input className="input" type="text" name="country" parent="address" defaultValue={customer?.address?.country} />
          </div>
        </div>

        <div className="block">
          <div className="field is-grouped">
            <p className="control">
              <button className="button" type="button" onClick={onCancel}>
                <span className="icon is-small">
                  <i className="fas fa-chevron-left"></i>
                </span>
                <span>Cancel</span>
              </button>
            </p>

            <p className="control">
              <button className="button is-success" type="submit">
                <span className="icon is-small">
                  <i className="fas fa-check"></i>
                </span>
                <span>Save</span>
              </button>
            </p>

            {onDelete && <p className="control">
              <button className="button is-danger is-outlined" type="button" onClick={onDelete}>
                <span>Delete</span>
                <span className="icon is-small">
                  <i className="fas fa-times"></i>
                </span>
              </button></p>}
          </div>
        </div>
      </form>
    </div>
  );
}