import { Route, Switch } from "react-router-dom";
import ListCustomers from "../customers/ListCustomers";
import AddCustomer from "../customers/AddCustomer";
import ViewCustomer from "../customers/ViewCustomer";

export default function CustomersView({ match }) {
  return (
    <Switch>
      <Route path={`${match.url}/add`} component={AddCustomer} />
      <Route path={`${match.url}/:id(\\w+)`} component={ViewCustomer} />
      <Route path={`${match.url}`} component={ListCustomers} />
    </Switch>
  );
}