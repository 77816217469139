import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import * as api from "../../services/basiccrm-api";
import CustomerDetails from "./CustomerDetails";
import { handleHttpException } from "../../helpers/http";

export default function ViewCustomer({ match }) {
  const customerId = match.params.id;
  const history = useHistory();

  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    const getCustomer = async () => {
      try {
        const data = await api.getCustomer(customerId);
        setCustomer(data);
      } catch (e) {
        if (e.status === 404)
          history.push('/customers');
        else
          handleHttpException(e);
      }
    }

    getCustomer();
  }, [customerId, history]);

  const saveCustomer = async customer => {
    try {
      await api.updateCustomer({ ...customer, id: customerId });
      history.push('/customers');
    } catch (e) {
      handleHttpException(e);
    }
  };

  const deleteCustomer = async customer => {
    if (!window.confirm("Are you sure you wish to delete this customer?"))
      return;

    try {
      await api.deleteCustomer(customer);
      history.push('/customers');
    } catch (e) {
      handleHttpException(e);
    }
  };

  return (
    <div>
      <div className="is-title-bar">
        <h1 className="title">View Customer</h1>
      </div>

      {
        !customer
          ? 'Loading...'
          : <div className="is-detail-container">
            <CustomerDetails
              customer={customer}
              onSave={saveCustomer}
              onCancel={() => history.push('/customers')}
              onDelete={deleteCustomer}
            />
          </div>
      }
    </div>
  );
}