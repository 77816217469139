import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Route, Switch } from "react-router-dom";
import SignupView from "./views/public/SignupView";
import ConfirmationView from "./views/public/ConfirmationView";
import { STRIPE_PUBLISHABLE_KEY } from "../config/app-config";

export default function PublicRouting() {
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

  return (
    <Elements stripe={stripePromise}>
      <Switch>
        <Route path="/signup" component={SignupView} />
        <Route path="/confirmation" component={ConfirmationView} />
      </Switch>
    </Elements>
  );
}