import { getJson, http } from "./http";
import { BASICCRM_API_URL } from "../config/app-config";

const apiUrl = BASICCRM_API_URL;

export const getCustomers = () =>
  getJson(`${apiUrl}/customers`);

export const getCustomer = id =>
  getJson(`${apiUrl}/customers/${id}`);

export const addCustomer = customer =>
  getJson(`${apiUrl}/customers`, 'POST', customer);

export const updateCustomer = customer =>
  http(`${apiUrl}/customers/${customer.id}`, 'PUT', customer);

export const deleteCustomer = customer =>
  http(`${apiUrl}/customers/${customer.id}`, 'DELETE');

export const signup = companyDetails =>
  getJson(`${apiUrl}/signups`, 'POST', companyDetails, false);

export const getSignupStatus = (signupId, key) =>
  getJson(`${apiUrl}/signups/${signupId}?key=${key}`, 'GET', null, false);