import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as api from "../../../services/basiccrm-api";

export default function ConfirmationView() {

  const [signupData, setSignupData] = useState({ id: null, key: null });
  const [signupSuccessful, setSignupSuccessful] = useState(false);

  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);

    const id = params.get('id');
    const key = params.get('key');

    if (id && key)
      setSignupData({ id, key });
  }, [search]);

  useEffect(() => {
    const checkStatus = async () => {
      let confirmed = false;
  
      try {
        const signupStatus = await api.getSignupStatus(signupData.id, signupData.key);
        if (signupStatus.active) {
          setSignupSuccessful(true);
          confirmed = true;
        } else {
          console.log({ message: "Request is still being processed..." });
        }
      } catch (err) {
        console.error(err);
      }
  
      if (!confirmed) {
        await new Promise(r => setTimeout(r, 2000));
        checkStatus();
      }
    };

    if (signupData.id && signupData.key)
      checkStatus();
  }, [signupData]);

  if (signupSuccessful)
    return (
      <div className="container p-5">
        <h1 className="title">Your sign-up was successful</h1>

        <div className="box">
          You should receive your login details via email momentarily.
        </div>
      </div>
    )

  return (
    <div className="container p-5">
      <h1 className="title">Your request is being processed...</h1>

      <div className="box">
        Please wait while we process your subscription
      </div>
    </div>
  )
}