import { useEffect, useReducer } from "react";
import * as api from "../../services/basiccrm-api";

const getDate = () => (new Date()).toLocaleDateString("en-GB", {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
});

const reducer = (state, action) => {
  switch (action.type) {
    case "UpdateTime":
      return {
        ...state,
        date: getDate()
      };
    case "UpdateCustomerCount":
      return {
        ...state,
        customerCount: action.customerCount
      };
    default:
      return state;
  }
};

export default function DashboardView() {
  const [state, dispatch] = useReducer(reducer, {
    customerCount: 0,
    date: getDate()
  });

  useEffect(() => {
    const getCustomers = async () => {
      const data = await api.getCustomers();
      dispatch({ type: "UpdateCustomerCount", customerCount: data.length });
    }

    setInterval(() => {
      dispatch({ type: "UpdateTime" });
    }, 1000);

    getCustomers();
  }, []);

  return <div>
    <div className="is-title-bar">
      <h1 className="title">Dashboard</h1>
    </div>

    <h1 className="subtitle">BasicCRM Dashboard</h1>

    <div className="tile is-ancestor">
      <div className="tile is-vertical is-8">
        <div className="tile">

          <div className="tile is-parent is-vertical">
            <article className="tile is-child notification is-white">
              <p className="title">Time &amp; Date</p>
              &nbsp;
              <p className="subtitle">{state.date}</p>
            </article>
            <article className="tile is-child notification is-white">
              <p className="title">Customers</p>
              &nbsp;
              <p className="subtitle">You currently have {state.customerCount} customers in the CRM system</p>
            </article>
          </div>

          <div className="tile is-parent">
            <article className="tile is-child notification is-white">
              <p className="title">
                Notifications
                &nbsp;
                <i className="fas fa-bell"></i>
              </p>
              &nbsp;
              <p className="subtitle">There are currently no new notifications</p>
            </article>
          </div>

        </div>
      </div>
    </div>

  </div>;
}